h1,h2 {font-family: 'Oswald', 'sans-serif';}

p,text {font-family: 'Source Sans Pro', sans-serif;}

.home {
    width: 90vw;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.home h1 {
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: .5em;
    padding: 0;
}

@media only screen and (min-width: 1000px) {
    .home {
        width: 70vw;
    }
}

