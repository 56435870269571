h1,h2 {font-family: 'Oswald', 'sans-serif';}

p,text {font-family: 'Source Sans Pro', sans-serif;}


.inner-container h2 {
    position: relative;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    top: 1em;
    margin-left: 1.5em;
}


.outer-container {
    display: grid;
    width: 50vw;
    gap: 1em;
    margin-bottom: 10em;
}

.inner-container {
    position: relative;
    border: 0.5px solid rgb(63, 63, 63);
    height: 80px;
    border-radius: 1em;
    width: 90vw;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    box-shadow: 0px 2px 5px  rgb(200, 200, 200);
    background-color: white;
}


.weather-icon {
    width: 50px;
}

.weather-container {
    position: relative;
    display: flex;
    margin-left: 9em;
    width: 20vw;
    top: -1.8em;
    right: -2em;
}


.weather-container img {
    margin-right: 1em;
}

.weather-container p {
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: .5em;
    padding: 0;
}

.weather-container a {
    position: relative;
    text-decoration: none;
    font-size: 2em;
    font-weight: 300;
    color: black;
    top: 0.3em;
    margin-left: 1.5em;
}




@media only screen and (min-width: 1000px) {
    .inner-container {
        width: 35vw;
    }
    .inner-container h2 {
        margin-left: 1em;
    }
    .outer-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1300px) {
    .weather-container {
        padding: 0 5em;
    }
}

@media only screen and (min-width: 1600px) {
    .weather-container {
        padding: 0 10em;
    }
}